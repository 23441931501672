@use '../includes' as *;

.modal {
  // dialog elements have
  // position: fixed by default
  // in user agent stylesheets

  // overriding user agent styles
  max-width: none;
  max-height: none;
  padding: 0;
  border: 0;
}

.btn__close {
  position: absolute;
  inset-block-start: $g16;
  inset-inline-end: $g16;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: $focusstatelight;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: $fog;
    }
  }
}
